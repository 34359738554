import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
export default function OrderItem() {

    useEffect(() => {
        document.title = 'Kawasaki.com Help Center';
    }, []);

    const navigateHelp = () => {
        window.location.href = '/helpcenter';
    }

    const location = useLocation();
    const navigate = useNavigate();
    const [orderInfo, setOrderInfo] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const [orderReturn, setOrderReturn] = useState(null);
    const [upsLinks, setUpsLinks] = useState([]);
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
    const [errmodal, setErrmodal] = useState(false);
    const navigateReturn = () => {
        if (location && location.state && location.state.id) {
            navigate('/returndetails', { state: { id: location.state.id } });
            return;
        }
    }

    useEffect(() => {
        debugger;
        async function fetchData() {
            const queryParameters = new URLSearchParams(window.location.search);
            const userID = queryParameters.get("userid");
            const orderID = queryParameters.get("orderid");
            const id = queryParameters.get("newtabid");
            if (userID && orderID) {
                //const response = await fetch("helpcenter/GetOrderID/" + id);
                const response = await fetch(`helpcenter/GetOrderID?userId=${userID}&orderId=${orderID}`);
                if (response.status != 200) {
                    navigate('/helpcenter');
                }
                const js = await response.json();
                navigate('/orderitem', { state: { id: js.value1 } });
                return;
            }
            else if (id) {
                location.state = { id: id };
                navigate('/orderitem', location);
                return;
            }
            if (!location.state || !location.state.id) {
                navigate('/helpcenter');
                return;
            }

            GetOrderInfo();
        }

        fetchData();
    }, [location])
    /* const promoRef = useRef(null)*/
    const GetOrderInfo = async () => {
        debugger;
        try {
            const response = await fetch("helpcenter/GetOrderInfo/" + location.state.id);
            if (response.status != 200) {
                throw new Error(response.statusText);
            }
            else {
                const js = await response.json();
                //console.log(js);
                setOrderInfo(js);
                setOrderItems(js.orderItems);
                setOrderReturn(js.orderReturns);
                debugger;
                let arr = new Array();
                for (let i = 0; i < js.trackingNum.length; i++) {
                    arr.push({ num: js.trackingNum[i], url: "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" + js.trackingNum[i] });
                }
                setUpsLinks(arr);
                let promoPrice = js.totalDiscount;
                promoPrice = promoPrice.substring(1);

                let taxLength = js.orderItems.length;
                let taxes = [];
                for (let i = 0; i < taxLength; i++) {
                    taxes.push(parseFloat(js.orderItems[i].tax.substring(1)));
                }
                window.dataLayer.push({
                    'info': "returns",
                    'coupon': js.promoCode,
                    'shipping': parseFloat(js.shippingFee.substring(1)),
                    'tax': taxes
                });
            }
        }
        catch (e) {
            console.log(e);
            navigate('/helpcenter');
        }
    };
    const CancelEntireOrder = async () => {
        debugger;
        try {
            const response = await fetch("helpcenter/CancelEntireOrder/" + location.state.id);
            if (response.status != 200) {
                errorModal();
                throw new Error(response.statusText);
            }
            else {
                confirmModal();
                //window.location.reload(true);
            }
        }
        catch (e) {
            console.log(e);
            //navigate('/helpcenter');
        }
    };
    const cancelModal = () => setShow(true);
    const handleCancelClose = () => setShow(false);
    const confirmModal = () => {
        setShow(false);
        setModal(true);
    }
    const handleConfirmClose = () => {
        setModal(false);
        window.location.reload(true);
    }
    const errorModal = () => {
        setShow(false);
        setErrmodal(true);
    }
    const handleErrorClose = () => setErrmodal(false);

    const navigateUPS = (trackingid) => {
        debugger;
        window.open("https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" + trackingid, '_blank');
    }
    const GoToOrder = (type) => {
        if (!orderInfo || !orderInfo.splitOrderPaymentDetail) {
            return;
        }
        if (type == 1) {
            if (orderInfo.splitOrderPaymentDetail.orderID1) {
                window.open('/orderitem?newtabid=' + orderInfo.splitOrderPaymentDetail.orderID1, '_blank', 'noopener,noreferrer')
            }
        }
        else {
            if (orderInfo.splitOrderPaymentDetail.orderID2) {
                window.open('/orderitem?newtabid=' + orderInfo.splitOrderPaymentDetail.orderID2, '_blank', 'noopener,noreferrer')
            }
        }
    }

    return (
        <>
            <Container className="checkout-container main-container">
                <Container>
                    <Card className="help-card">
                        <Row className="order-item-title">
                            <Col sm={11}>
                                <h1 className="heading card-main-title">ORDER NUMBER: {orderInfo && orderInfo.orderID}</h1>
                            </Col>
                            <Col sm={1}>
                                <p className="underline order-back" onClick={navigateHelp} aria-label="Link to go back to the Help Center page" tabIndex="0">Back</p>
                            </Col>
                            <h3>Date Ordered: {orderInfo && orderInfo.dateOrdered}</h3>
                            <h3>Order Status: {orderInfo && orderInfo.orderStatus}</h3>
                        </Row>
                        <Row className="order-ship-info justify-content-center">
                            <Col sm={3}>
                                <Row className="order-subdivison-head">
                                    <p className="order-title-text">Shipping Address</p>
                                </Row>
                                {
                                    orderInfo && orderInfo.dealerName !== "" ?
                                        <>
                                            <Row className="order-text-info">
                                                <p className="bold">Ship To Dealer:</p>
                                                <p>{orderInfo && orderInfo.dealerName}</p>
                                                <p>{orderInfo && orderInfo.dealerAddressLine1}</p>
                                                <p>{orderInfo && orderInfo.dealerAddressLine2}</p>
                                            </Row>
                                        </> :
                                        <></>
                                }
                                <Row className="order-text-info">
                                    {orderInfo && orderInfo.dealerName !== "" ? <p className="bold">Customer Information:</p> : <></>  }
                                    <p>{orderInfo && orderInfo.firstName} {orderInfo && orderInfo.lastName}</p>
                                    <p>{orderInfo && orderInfo.uPSLocation}</p>
                                    <p>{orderInfo && orderInfo.address}</p>
                                    <p>{orderInfo && orderInfo.cityStateZip}</p>
                                    <p>{orderInfo && orderInfo.phone}</p>
                                    <p>{orderInfo && orderInfo.email}</p>
                                </Row>
                            </Col>
                            <Col sm={{ span: 3, offset: 1 }}>
                                <Row className="order-subdivison-head">
                                    <p className="order-title-text">Shipping Information</p>
                                </Row>
                                <Row className="order-text-info">
                                    <p className="bold">Shipping Method:</p>
                                    <p>{orderInfo && orderInfo.shipping_options}</p>
                                </Row>
                                <Row className="order-text-info">
                                    <p className="bold">Date Shipped:</p>
                                    <p>{orderInfo && orderInfo.dateShipped}</p>
                                </Row>
                                <Row className="order-text-info">
                                    <p className="bold">UPS Tracking Number:</p>
                                    {upsLinks && upsLinks.map((d) =>
                                        <a className="green-under" href={d.url} target="_blank" rel="noreferrer">{d.num}</a>
                                    )}
                                </Row>
                            </Col>
                            <Col sm={{ span: 3, offset: 1 }}>
                                <Row className="order-subdivison-head">
                                    <p className="order-title-text">Payment Method</p>
                                </Row>
                                <Row className="order-text-info">
                                    <p className="bold">Payment:</p>
                                    <p>{orderInfo && orderInfo.cardNum} {orderInfo && orderInfo.paymentType}</p>
                                    <p>Date: {orderInfo && orderInfo.dateOrdered}</p>
                                    <p>Amount: {orderInfo && orderInfo.totalPaid}</p>
                                </Row>
                                {orderInfo && orderInfo.splitOrderPaymentDetail ?
                                    <><Row className="order-text-info">
                                        <p>Order <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrder}>{orderInfo.splitOrderPaymentDetail.orderID1}</span></p>
                                        <p>{orderInfo.splitOrderPaymentDetail.orderAmout1}</p>
                                    </Row>
                                        <Row className="order-text-info">
                                            <p>Order <span className="order-number" id="transaction-id" tabIndex="1" onClick={GoToOrder}>{orderInfo.splitOrderPaymentDetail.orderID2}</span></p>
                                            <p>{orderInfo.splitOrderPaymentDetail.orderAmout2}</p>
                                        </Row>                                     </>
                                    :
                                    <></>
                                }

                                {
                                    (orderInfo && orderInfo.refund) ? 
                                        <Row className="order-text-info">
                                            {orderInfo && orderInfo.refund.map((d) =>

                                                <>
                                                    <p className="bold">Refund:</p>
                                                    <p>Date: {d[0]}</p>
                                                    <p>Amount: {d[1]}</p>
                                                    <p className="order-refund-bottom">Reference #: {d[2]}</p></>
                                            )}
                                        </Row>:<></>
                                }
                               
                               

                            </Col>
                        </Row>
                        <Row className="order-table order-page">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Image</th>
                                        <th>Details</th>
                                        <th>Status</th>
                                        <th>Quantity</th>
                                        <th>Unit Price</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderItems && orderItems.map((d, idx) =>
                                        <tr>
                                            <td>{idx + 1}</td>
                                            <td className="table-img"><img src={d.img} alt="Product" className="item-image"></img></td>
                                            <td className="table-left">
                                                <p>{d.description}</p>
                                                <p>SKU: {d.partID}</p>
                                                <br />
                                                {!!(d.vin)? <><p>VIN: {d.vin}</p><a className="green-under" href="https://www.kawasaki.com/en-us/owner-center/register" target="_blank" rel="noreferrer">Warranty Registration</a></> : <></>}
 
                                            </td>
                                            <td>{d.status}</td>
                                            <td>{d.qty}</td>
                                            <td>{d.unitPrice}</td>
                                            <td>{d.subtotal}</td>
                                        </tr>
                                    )}
                                    <tr className="price-table first-price-row">
                                        <td></td>
                                        <td colSpan={2}>Subtotal</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{orderInfo && orderInfo.subtotal}</td>
                                    </tr>
                                    <tr className="price-table">
                                        <td></td>
                                        <td colSpan={2}>Shipping + Handling</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{orderInfo && orderInfo.shippingFee}</td>
                                    </tr>
                                    <tr className="price-table">
                                        <td></td>
                                        <td colSpan={2}>Sales Tax</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{orderInfo && orderInfo.tax}</td>
                                    </tr>
                                    <tr className="price-table">
                                        <td></td>
                                        <td colSpan={2}>Promo Applied{(orderInfo && orderInfo.promoCode) ? `: ${orderInfo.promoCode}` : ""}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ color: 'red' }}>{(orderInfo && orderInfo.totalDiscount) ? `(${orderInfo.totalDiscount})` : "$0.00"}</td>
                                        {/* {orderInfo && orderInfo.totalDiscount > 0 ? <td style={{ color: 'red' }}>{orderInfo.totalDiscount}</td> : <td>$0.00</td>}*/}
                                        {/* <td ref={promoRef}>{(orderInfo && orderInfo.totalDiscount > 0) ? `(${orderInfo.totalDiscount})` : "$0.00"}</td> */}
                                    </tr>
                                    <tr className="price-table total-table">
                                        <td></td>
                                        <td colSpan={2}>TOTAL</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{orderInfo && orderInfo.total}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        {
                            (orderInfo && orderInfo.orderStatus == "QUEUED") &&
                            <Row className="cancel-btn">
                                <Col sm={{ span: 4, offset: 1 }}>
                                    <Button variant="danger" onClick={cancelModal}>
                                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        CANCEL ORDER
                                    </Button>
                                </Col>
                            </Row>
                        }

                        {(orderReturn && orderReturn.length > 0) &&
                            <Row className="return-info-lookup">
                                <h5>RETURNED ITEMS</h5>
                            </Row>}
                        {orderReturn &&
                            orderReturn.map((d, idx) =>
                                <>

                                    <Row className="order-ship-info justify-content-center return-info-details">
                                        <Col sm={3}>
                                            <Row className="order-text-info">
                                                <p>Reference #: <span className="bold">{d.ref}</span></p>
                                                <p>Rma #: <span className="bold">{d.rma}</span></p>
                                                <p>Return Date: <span className="bold">{d.returnDate}</span></p>
                                                <p>Shipping Label: {d.shippingLabel && <a href={d.shippingLabel} target="_blank">Print Shipping Label</a>}</p>
                                            </Row>
                                        </Col>
                                        <Col sm={{ span: 3, offset: 1 }}>
                                            <Row className="order-text-info">
                                                <p>Refunded To: <span className="bold">{d.refundedTo}</span></p>
                                                <p>Card Number: <span className="bold">{d.cardNum}</span></p>
                                                <p>Refund Date: <span className="bold">{d.fefundDate}</span></p>
                                                <p>Refund Amount: <span className="bold">{d.refundAmount}</span></p>
                                            </Row>
                                        </Col>
                                        <Col sm={{ span: 3, offset: 1 }}>
                                            <Row className="order-text-info return-print">
                                                {d.shippingLabel && <a href={d.shippingLabel} target="_blank">Print Shipping Label</a>}

                                            </Row>
                                        </Col>
                                    </Row>
                                    {d.orderItems &&
                                        <Row className="order-table order-page">
                                            <Table hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Image</th>
                                                        <th>Details</th>
                                                        <th>Return Reason</th>
                                                        <th>Comments</th>
                                                        <th>Status</th>
                                                        <th>Quantity</th>
                                                        <th>Unit Price</th>
                                                        <th>Subtotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {d.orderItems && d.orderItems.map((itm, itmidx) =>
                                                        <>
                                                            <tr>
                                                                <td>{itmidx + 1}</td>
                                                                <td className="table-img"><img src={itm.img} alt="Product" className="item-image"></img></td>
                                                                <td className="table-left">
                                                                    <p>{itm.description}</p>
                                                                    <p>SKU: {itm.partID}</p>
                                                                </td>
                                                                <td>{itm.reason}</td>
                                                                <td>{itm.comments}</td>
                                                                <td>{itm.status}</td>
                                                                <td>{itm.qty}</td>
                                                                <td>{itm.unitPrice}</td>
                                                                <td>{itm.subtotal}</td>
                                                            </tr>

                                                        </>
                                                    )}
                                                    <tr className="price-table first-price-row">
                                                        <td></td>
                                                        <td colSpan={2}>Subtotal</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{d.subtotal}</td>
                                                    </tr>
                                                    <tr className="price-table">
                                                        <td></td>
                                                        <td colSpan={2}>Sales Tax</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{d.tax}</td>
                                                    </tr>
                                                    <tr className="price-table total-table">
                                                        <td></td>
                                                        <td colSpan={2}>REFUND</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{d.total}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Row>}
                                </>

                            )}
                        <Row className="justify-content-center">
                            <img src="/images/Kawasaki_Brand_Logo_Red_Black.png" alt="Kawasaki red logo" className="order-item-logo" />
                        </Row>
                        <Row className="justify-content-center order-item-footer">
                            <Col sm={3}>
                                <p>Questions about this order?</p>
                                <p className="green-under clickable" onClick={navigateHelp} tabIndex="10">Visit Our Help Center</p>
                            </Col>
                            {(orderInfo && orderInfo.canReturn) &&
                                <Col sm={{ span: 3, offset: 1 }}>
                                    <p>Problems with this order?</p>
                                    <p className="green-under clickable" onClick={navigateReturn} tabIndex="11">Return Item(s)</p>
                                </Col>}
                        </Row>
                        <Row className="order-bottom">
                            <p className="order-item-thanks">Thank you for shopping with Kawasaki Motors Corp., U.S.A.!</p>
                        </Row>
                    </Card>

                    <Modal show={show} onHide={handleCancelClose} size="lg" centered>
                        <Modal.Header closeButton closeVariant="white"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Modal.Title>CANCEL ORDER</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>Are you sure you want to cancel this order?</div>
                        </Modal.Body>
                        <Modal.Footer>
                            {/*<Button variant="secondary" onClick={confirmModal}>*/}
                            <Button variant="danger" onClick={CancelEntireOrder}>
                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" role="presentation">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                                CANCEL ORDER
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={modal} onHide={handleConfirmClose} size="lg" centered>
                        <Modal.Header closeButton closeVariant="white"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Modal.Title>ORDER CANCELED</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>Your order has been canceled, and a refund will be issued to your original payment method within 3-5 business days.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleConfirmClose}>
                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                                CLOSE
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={errmodal} onHide={handleErrorClose} size="lg" centered>
                        <Modal.Header closeButton closeVariant="white"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <Modal.Title>ORDER CANCEL FAILED</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>There has been an error in cancelling your order, please try again later.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleErrorClose}>
                                <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill green" viewBox="0 0 16 16" role="presentation">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                                CLOSE
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </Container>
        </>
    )
}